body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Gochi+Hand&display=swap');

html, body, #root {
  min-height: 100% !important;
  height: 100%;
  background-color: #181b1f;
  // color: #000;
  /* font-family: 'Roboto Mono', monospace, sans-serif; */
  /* font-family: 'Gochi Hand', cursive; */
  font-family: 'Open Sans', sans-serif;
}

/* CUSTOM CHROME SCROLLBAR */
/* Let's get this party started */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* -webkit-border-radius: 10px; */
  border-radius: 10px;
  background: rgba(64,64,64,0.8);
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(64,64,64,0.4);
}

.ReactModalPortal{
  position: relative;
  z-index: 9999;
  .question{
    margin-bottom: 2rem;
    span{
      font-weight: 700;
    }
  }
  .buttons{
    display: flex;
    justify-content: center;
  }
}