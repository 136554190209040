.song-tab-editor{
    width: 100%;
    height: 100%;
    flex-grow: 1;

    display:flex;
    flex-direction: column;

    // & * {
    //     font-family: 'Roboto Mono', monospace, sans-serif;
    // }

    label{
        font-size: .6rem;
        display: inline-block;
        color: gray;
        text-transform: uppercase;
        width: 3.5rem;
        text-align: right;
        margin-right: .3rem;
        margin-left: 0;
    }
    label[for="tab"]{
        text-align: left;
        margin-right: 0;
    }

    input{
        color: black;
        font-size: 1rem;
    }
    .text-area-wrapper{
        width: 100%;
        flex-grow:1;
        border-width: 1px;
        border-radius: 10px;
        border: 1px solid darkgray;
        overflow: hidden;
        background-color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;

        //TODO activate the focus with JS
        &:focus{
            outline-color:rgb(0, 255, 0);
            outline-offset:0px;
            outline-style:solid;
            outline-width:2px;
        }

        textarea:focus{
            outline: none;
        }

        textarea {
            /* BEGIN Base setting for textarea */
            -webkit-writing-mode: horizontal-tb !important;
            text-rendering: auto;
            color: -internal-light-dark-color(black, white);
            letter-spacing: normal;
            word-spacing: normal;
            text-transform: none;
            text-indent: 0px;
            text-shadow: none;
            display: inline-block;
            text-align: start;
            -webkit-appearance: textarea;
            background-color: -internal-light-dark-color(white, black);
            -webkit-rtl-ordering: logical;
            flex-direction: column;
            resize: auto;
            cursor: text;
            white-space: pre-wrap;
            overflow-wrap: break-word;
            margin: 0em;
            font: 400 13.3333px Arial;
            border-width: 1px;
            border-style: solid;
            border-color: rgb(169, 169, 169);
            border-image: initial;
            padding: 2px;
            /* END Base setting for textarea */

            width: calc(100% - .4rem);
            height: calc(100% - .4rem);

            white-space: pre;

            resize: none;
            font-family: 'Roboto Mono', monospace, sans-serif;
            color: black;
            font-size: 1rem;
            margin: 0;
            border: none;

        }

    }
}

/*
CUSTOM SCROLL BARS https://css-tricks.com/custom-scrollbars-in-webkit/
https://css-tricks.com/examples/WebKitScrollbars/

*/
/* For the "inset" look only */
// html {
//     overflow: auto;
// }
// body {
//     position: absolute;
//     top: 20px;
//     left: 20px;
//     bottom: 20px;
//     right: 20px;
//     padding: 30px;
//     overflow-y: scroll;
//     overflow-x: hidden;
// }

// /* Let's get this party started */
// ::-webkit-scrollbar {
//     width: 12px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//     -webkit-border-radius: 10px;
//     border-radius: 10px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//     -webkit-border-radius: 10px;
//     border-radius: 10px;
//     background: rgba(255,0,0,0.8);
//     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
// }
// ::-webkit-scrollbar-thumb:window-inactive {
// 	background: rgba(255,0,0,0.4);
// }
